@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

* {
    overflow-x: hidden;
}

body {
    background-color: black;
    color: white;
    font-family: 'Plus Jakarta Sans', sans-serif ;
}

html {
    scroll-behavior: smooth;
}



p {
    position: relative;
    z-index: 10;
    background-color: transparent;
}


.title-section {
    
    display: flex;
}

.title-section .left {
    padding-top: 7%;
    width: 60%;
    padding-left: 15%;
    position: static;
}

.title-section .right {
    
}

.square-1 {
    position: absolute;
    background: linear-gradient(to right, red, blue);
    border-radius: 200px;
    margin-top: -15%;
    right: -15%;
    opacity: 0.4;
    width: 900px;
    height: 900px;
    animation: rotate 50s infinite linear;
    z-index: 1;
    overflow: visible;
    rotate: 225deg;
}
.square-2 {
    position: absolute;
    background: linear-gradient(to right, red, blue);
    border-radius: 200px;
    margin-top: -15%;
    right: -15%;
    opacity: 0.4;
    width: 600px;
    height: 600px;
    animation: rotate 50s infinite linear;
    z-index: 1;
    overflow: visible;
    rotate: 100deg;
}

.square-3 {
    position: absolute;
    background: linear-gradient(to right, red, blue);
    border-radius: 200px;
    margin-top: -15%;
    right: -15%;
    opacity: 0.4;
    width: 750px;
    height: 750px;
    animation: rotate 50s infinite linear;
    z-index: 1;
    overflow: visible;
    rotate: 80deg;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg)
    }
}

.title {
    font-weight: lighter;
    font-size: 60px;
    font-weight: 800;
}

.subtitle {
    font-size: 50px;
    font-weight: 600;
    margin-top: -5%;
}

.description {
    font-size: 45px;
    margin-top: -5%;
    font-weight: 200;
}

.normal {
    font-size: 30px;
    font-weight: 200;
}




header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    z-index: 100;
    background-color: #111111;
    z-index: 1000;
    overflow: hidden;
}

nav {
    background-color: #111111;
}

.navlinks {
    list-style: none;
    background-color: #111111;
    display: flex;
    align-items: center;
}

.navlinks li {
    /* padding: 6px; */
    display: inline-block;
    margin-right: 100px;
    background-color: #111111;
}

.navlinks li a {
    transition: all 0.3s ease 0s;
    background-color: #111111;
    font-size: 20px;
    font-weight: 300;
    text-decoration: none;
    color: white;
}

.navlinks li a button {
    color: white;
}

.navlinks li a:hover {
    color: rgb(198, 179, 245);
    font-weight: 300;
    cursor: pointer;
} 

/* .get-started {
    text-align: left;
    width: 180px;
}

.get-started button {
    font-size: 20px;
    background-color: white;
    color: black;
    border-radius: 30px;
    padding: 12px;
    transition: all 0.5s ease 0s;
    font-weight: 500;
    
}

.get-started button:hover {
    background-color: rgb(204, 78, 78);
    color: white;
    cursor: pointer;
} */

.logo-black {
    height: 40px;
}

.heading {
    font-size: 50px;
    font-weight: 600;
    padding-left: 15%;
    padding-top: 10%;
}

.about-section {
    margin-top: 200px;
}

.about-blocks {
    display: flex;
    padding-left: 15%;
    background: radial-gradient(closest-side, rgb(0, 0, 47), black)
}

.subheading {
    font-size: 40px;
    font-weight: 500;
}

.row {
    width: 700px;
    background-color: transparent;
}

.block {
    background-color: transparent;
}

.description {
    font-size: 25px;
    width: 80%;
}

.file-rows {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}

.column p {
    padding: 0px;
}

.column {}

.file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.tutorial-section {
    margin-top: 200px;
}

.tutorial-block {
    width: 70%;
    margin-right: auto;
    margin-left: auto;
}

.tutorial-text {
    font-size: 25px;
}







.file-table {

    color: white;
    /* background-color: rgba(1, 4, 104, 0.188); */
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.274);
    margin: 100px 20% 0% 20%;
    padding-bottom: 200px;
    margin-bottom: 50px;
    /* box-shadow: rgba(72, 135, 202, 0.5) 0px 0px 20px 0px; */
    z-index: 100;


}

.download-button {
    background-color: rgb(198, 179, 245);
    border-color: transparent;
    border-radius: 100px;
    margin-left: 10px;
    transition: all 0.3s ease 0s;

}

.download-button:hover {
    cursor: pointer;
    background-color: rgb(143, 121, 200);
}

.download-logo {
    height: 20px;
    color: white;

    border-radius: 7px;
}

.filename {
    color: white;
    text-decoration: none;
}

.warning {
    color: rgb(252, 102, 102);
}












.top {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.top-title {
    font-size: 30px;
}


.dashboard-squares {
    overflow-y: hidden;
    z-index: 1;
}


.square-4 {
    position: absolute;
    background: linear-gradient(to right, rgba(1, 56, 80, 0.556), rgb(198, 179, 245));
    border-radius: 200px;
    margin-top: -15%;
    right: -15%;
    opacity: 0.4;
    width: 900px;
    height: 900px;
    animation: rotate 120s infinite linear;
    z-index: 1;
    overflow: visible;
    rotate: 225deg;
}
.square-5 {
    position: absolute;
    background: linear-gradient(to right, rgba(1, 56, 80, 0.757), rgb(198, 179, 245));
    border-radius: 200px;
    margin-top: 0;
    right: -15%;
    opacity: 0.4;
    width: 600px;
    height: 600px;
    animation: rotate 100s infinite linear;
    z-index: 1;
    overflow: visible;
    rotate: 135deg;
}

.square-6 {
    position: absolute;
    background: linear-gradient(to right, rgba(1, 56, 80, 0.556), rgb(198, 179, 245));
    border-radius: 200px;
    margin-top: -10%;
    right: -15%;
    opacity: 0.4;
    width: 750px;
    height: 750px;
    animation: rotate 150s infinite linear;
    z-index: 1;
    overflow: visible;
    rotate: 45deg;
    
}

.dashboard-page {
    position: relative;
    overflow-y: hidden;
    height: 100vh;

}

.submit {
    background-color: #c6b3f5;
    transition: all 0.3s ease 0s;
    height: 40px;
    width: 100px;
    border-radius: 100px;
    color: black;
    font-family: 'Plus Jakarta Sans', sans-serif ;
    
    
}

.submit:hover {
    cursor: pointer;
    background-color:   #866bc8;
    
}

.choose-files {
    
  }


.selectChunkSize {
    height: 300px;
    border-width: 5px;
    border-color: aliceblue;
}

.chunkPage {
    display: flex;
    justify-content: center;
    margin-top:10%;

    
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

input {
    border-radius: 10px;
    height: 30px;
    width: 200px;
    font-size: 18px;
    font-family: 'Plus Jakarta Sans', sans-serif ;
    padding-left: 10px;
}


.labelling {
    display: flex;
    
    justify-content: center;
}


.labelling-box {
    margin-top: 15%;
}

ul {
    padding-left: 40px; 

  }
  
  li {
    line-height: 1.5;   
    font-size: 18px;
    font-weight: 100;
  }

  .blt-hdg {
    font-weight: 700;
    color: rgb(211, 199, 241);  
    }

  .focus {
    font-style: italic;
    font-weight: 400;
    text-decoration: underline;
  }
  
  .highlight {
    color: rgb(217, 204, 248); 
    font-weight: 400;
  }


.rowSkeleton {
    margin-bottom: "20px";
}


/* Wrapper to center the container */
.center-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #000; /* Black background */
  }
  
  /* Shared container styling for login and register */
  .shared-container {
    width: 100%;
    max-width: 400px;
    background: #1c1c1c; /* Dark gray for contrast */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(255, 255, 255, 0.1); /* Soft white shadow */
    text-align: center;
    color: #fff; /* White text for better visibility */
  }
  
  .shared-container h2 {
    margin-bottom: 20px;
    color: #fff;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    color: #ccc; /* Light gray for labels */
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #555; /* Subtle border */
    background-color: #333; /* Darker input background */
    color: #fff; /* White text */
  }
  
  .input-group input:focus {
    border-color: #007bff; /* Blue focus border */
    outline: none;
  }
  
  .error-message {
    color: #ff4d4f; /* Red for errors */
    margin-top: -10px;
    margin-bottom: 10px;
  }
  
  /* Button group styling */
  .button-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: #007bff; /* Blue buttons */
    color: #fff;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }

  .google-btn {
    background-color: red;
  }
  .google-btn:hover {
    background-color: rgb(181, 5, 5);
  }
  
  button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  button:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
  
  .shared-container p a {
    color: #1e90ff;
    text-decoration: none;
  }
  
  .shared-container p a:hover {
    text-decoration: underline;
  }


  .choose-files {
    position: relative;
    display: inline-block;
  }
  
  input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  
  .custom-file-button {
    background-color: #007bff; 
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
  } 
  
  .custom-file-button:hover {
    background-color: #0056b3;
  }

  .userEmail {
    font-size: 20px;
    font-weight: 300;
  }
  
  
